import React from 'react';
import { push } from 'connected-react-router';
import { Login, Logout } from 'mdi-material-ui';
import {
  Menu,
  MenuItem,
  Badge,
  IconButton,
} from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import actions from '../../../redux/actions';

export default ({ classes, user, dispatch }) => {

  if (!user.email) {
    return (
      <IconButton
        className={classes.loginButton} 
        aria-label="login"
        onClick={() => dispatch(push(`/sign-in`))}
      >
        <Login />
      </IconButton>
    )
  }

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch({
      type: actions.LOGOUT_USER
    })
  }

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </div>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={() => dispatch(push(`/account`))}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Perfil</p>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IconButton aria-label="logout" color="inherit">
            <Logout />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </Menu>

    </div>
  )
}