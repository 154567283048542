import { put, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import genericErrors from '../../actions/genericErrors';

function* upload(axios, action) {
  try {
    const { file, contentType, name } = action.payload;
    yield axios.put('/api/accounts/avatar', { file, contentType, name });
    yield put({ type: actions.LOGGED_USER });
  } catch (err) {
    yield put(genericErrors(action.type, err));
  }
}

export default function* watchUpload(axios) {
  yield takeLatest(actions.UPLOAD_AVATAR, upload, axios);
}