import { takeLatest, put } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/teams/fetchSuccessful';

function* prepareCheckin(axios, action) {
  try {
    const eventId = action.payload.resourceId;
    const { data: { data: payload } } = yield axios.post(`/api/teams/checkin/${eventId}`);
    console.log('Checkin no eventId', eventId, payload);
    // yield put({
    //   type: actions.TEAM_FETCH_ENTITY_SUCCESSFUL,
    //   meta: {
    //     reducer,
    //   },
    //   payload,
    // });

  } catch (err) {
    console.log(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }

}

export default function* watchCheckin(axios) {
  yield takeLatest(actions.TEAMS_CHECKIN, prepareCheckin, axios);
}
