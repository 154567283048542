const landingPageStyle = {
  container: {
    // position: 'fixed',
    // zIndex: "12",
    color: "#FFFFFF",
    // padding: 0,
    // margin: 0,
    minWidth: '100%',
    minHeight: '100%',
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
  title: {
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "absolute",
    // zIndex: "3",
    marginTop: 70,
  },
  mainRaised: {
    minWidth: '100%',
    minHeight: '100%',
  }
};

export default landingPageStyle;