import { takeLatest, put } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/teams/fetchSuccessful';

function* prepareFetchEntities(axios, action) {
  try {
    const id = action.payload
    const { data: { team: payload } } = yield axios.get(`/api/teams/${id}`);
    
    yield put({
      type: actions.TEAM_FETCH_ENTITY_SUCCESSFUL,
      meta: {
        reducer,
      },
      payload,
    });

  } catch (err) {
    console.log(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }

}

export default function* watchTeamPage(axios) {
  yield takeLatest(actions.TEAM_FETCH_ENTITY, prepareFetchEntities, axios);
}
