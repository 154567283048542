const NAMESPACE_MUI = 'JJTEAM_';

const actions = {


  POPULATE_ENTITY: `${NAMESPACE_MUI}POPULATE_ENTITY`,
  PAGINATE_ENTITY: `${NAMESPACE_MUI}PAGINATE_ENTITY`,
  SAVE_ENTITY: `${NAMESPACE_MUI}SAVE_ENTITY`,
  DELETE_ENTITY: `${NAMESPACE_MUI}DELETE_ENTITY`,
  EDIT_ENTITY: `${NAMESPACE_MUI}EDIT_ENTITY`,
  MERGE_ENTITY: `${NAMESPACE_MUI}MERGE_ENTITY`,


  FETCH_ENTITY: `${NAMESPACE_MUI}FETCH_ENTITY`,
  FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}FETCH_ENTITY_SUCCESSFUL`,
  ENTITY_ERROR: `${NAMESPACE_MUI}ENTITY_ERROR`,

  SIGN_IN_SELECT:  `${NAMESPACE_MUI}SIGN_IN_SELECT`,
  SIGN_UP: `${NAMESPACE_MUI}SIGN_UP`,
  SIGN_UP_FAILED: `${NAMESPACE_MUI}SIGN_UP_FAILED`,
  LOGIN_USER_PASSWORD: `${NAMESPACE_MUI}LOGIN_USER_PASSWORD`,
  LOGIN_USER_SUCCESSFUL: `${NAMESPACE_MUI}LOGIN_USER_SUCCESSFUL`,
  LOGIN_USER_FAILED: `${NAMESPACE_MUI}LOGIN_USER_FAILED`,
  LOGGED_USER: `${NAMESPACE_MUI}LOGGED_USER`,
  LOGOUT_USER: `${NAMESPACE_MUI}LOGOUT_USER`,

  SIGN_IN_CHANGE:  `${NAMESPACE_MUI}SIGN_IN_CHANGE`,

  RECOVERY_USER: `${NAMESPACE_MUI}RECOVERY_USER`,
  RECOVERY_USER_ERROR: `${NAMESPACE_MUI}RECOVERY_USER_ERROR`,
  RESET_PASSWORD: `${NAMESPACE_MUI}RESET_PASSWORD`,
  RESET_PASSWORD_ERROR: `${NAMESPACE_MUI}RESET_PASSWORD_ERROR`,
  UPLOAD_AVATAR: `${NAMESPACE_MUI}UPLOAD_AVATAR`,
  SAVE_ACCOUNT: `${NAMESPACE_MUI}SAVE_ACCOUNT`,
  FETCH_CEP: `${NAMESPACE_MUI}FETCH_CEP`,

  RELOAD: `${NAMESPACE_MUI}RELOAD`,

  TEAMS_CHANGE_TAB: `${NAMESPACE_MUI}TEAMS_CHANGE_TAB`,
  TEAMS_FETCH_ENTITY: `${NAMESPACE_MUI}TEAMS_FETCH_ENTITY`,
  TEAMS_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}TEAMS_FETCH_ENTITY_SUCCESSFUL`,
  TEAM_FETCH_ENTITY: `${NAMESPACE_MUI}TEAM_FETCH_ENTITY`,
  TEAM_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}TEAM_FETCH_ENTITY_SUCCESSFUL`,

  GUESTS_FETCH: `${NAMESPACE_MUI}GUESTS_FETCH`,
  GUESTS_ADD: `${NAMESPACE_MUI}GUESTS_ADD`,
  GUESTS_REMOVE: `${NAMESPACE_MUI}GUESTS_REMOVE`,
  GUESTS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}GUESTS_FETCH_SUCCESSFUL`,
  GUESTS_POPULATE_ENTITY: `${NAMESPACE_MUI}GUESTS_POPULATE_ENTITY`,
  GUESTS_POPULATE_ENTITY_SIGNUP: `${NAMESPACE_MUI}GUESTS_POPULATE_ENTITY_SIGNUP`,
  GUESTS_SIGNUP: `${NAMESPACE_MUI}GUESTS_SIGNUP`,

  TEAMS_CHECKIN: `${NAMESPACE_MUI}TEAMS_CHECKIN`,
    
};

export { actions as default };