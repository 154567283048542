import { takeLatest, put } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/home/fetchSuccessful';

function* prepareFetchEntities(axios, action) {
  try {

    const { data: { data: payload } } = yield axios.get(`/api/teams`);
    
    yield put({
      type: actions.TEAMS_FETCH_ENTITY_SUCCESSFUL,
      meta: {
        reducer,
      },
      payload,
    });

  } catch (err) {
    console.log(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }

}

export default function* watchMapList(axios) {
  yield takeLatest(actions.TEAMS_FETCH_ENTITY, prepareFetchEntities, axios);
}
