
import actions from '../../actions';

export default (state, action) => {

  if (actions.TEAM_FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      team: action.payload
    }
  }
  return state;
}