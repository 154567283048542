import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './components/RouteWithLayout';
import Home from './views/home';
import Teams from './views/team';
import SignIn from './views/signin';
import Account from './views/account';
import AccountRecovery from './views/recovery';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={Home}
        exact
        path="/home"
      />
      <RouteWithLayout
        component={Teams}
        exact
        path="/teams/:id"
      />
      <RouteWithLayout
        component={Account}
        exact
        path="/account"
      />
      <RouteWithLayout
        component={SignIn}
        exact
        path="/sign-in"
      />
      <RouteWithLayout
        component={AccountRecovery}
        exact
        path="/recovery"
      />
      <RouteWithLayout
        component={Teams}
        exact
        path="/:team_slug"
      />
    </Switch>
  );
};

export default Routes;
