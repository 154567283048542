import actions from '../../actions';
import validate from 'validate.js';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};


export default (state, action) => {
  if (actions.SIGN_IN_CHANGE === action.type) {
    const signIn = state.signIn;
    const values = {
      ...signIn.values,
      ...action.payload,
    };
    const errors = validate(values, schema);
    return {
      ...state,
      signIn: {
        isValid: errors ? false : true,
        values,
        errors: errors || {},

      }
    }
  }

  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state;
}