
import actions from '../../actions';

export default (state, action) => {

  if (actions.MERGE_ENTITY === action.type) {
    const stateEntity = state[action.meta.entity];
    return {
      ...state,
      [action.meta.entity]: {
        ...stateEntity,
        ...action.payload,
      },
    }
  }

  return state;
}