import { put, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/signIn';

const entity_new =  {
  isValid: false,
  values: {
    displayName: '',
    photoURL: '',
    cep: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    phone: '',
  },
  touched: {},
  errors: {}
};

const propperties = [
  'displayName',
  'photoURL',
  'cep',
  'endereco',
  'numero',
  'bairro',
  'cidade',
  'uf',
  'phone',
]

const calculate = (complete, total) => ( (complete * 100) / total );;

const profileCompleteness = (account_edit) => {
  const complete = propperties.reduce((percent, field) => 
    (!!account_edit[field]) ?  percent + 1: percent, 0);
  const total = propperties.length;
  return Math.round(calculate(complete, total));
}

function* loggedUser(axios, action) {
  try {
    const { data: { data } } = yield axios.get('/logged');
    const user = data || {};
    const account_edit = user.profile ? {
      ...entity_new,
      values: {
        ...user.profile,
        photoURL: user.photoURL,
      }
    } : { ...entity_new };
    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL,
      meta: { entity: 'user', reducer, },
      payload: {
        user,
        loading: false,
        account_edit,
        profileCompleteness: profileCompleteness(account_edit.values),
      },
    });
  } catch (err) {
    yield put({
      type: actions.LOGIN_USER_FAILED,
      payload: err,
      ga: { category: 'ERROR', action: action.type } 
    });
  }
}

export default function* watchLogged(axios) {
  yield takeLatest(actions.LOGGED_USER, loggedUser, axios);
}