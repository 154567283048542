import React from 'react';

import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

import actions from './redux/actions';
import createReducer from './redux/reducer';
import sagas from './redux/sagas';

import Routes from './Routes';

import './App.css';

const browserHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  routerMiddleware(browserHistory),
  sagaMiddleware,
];
const composeEnhancers = composeWithDevTools({});
const composed = composeEnhancers(applyMiddleware(...middlewares));
const reducer = createReducer(browserHistory);
const store = createStore(reducer, composed);
sagaMiddleware.run(sagas);
window.store = store;

export default class App extends React.Component {

  componentDidMount() {
    store.dispatch({ type: actions.LOGGED_USER });
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={browserHistory}>
            <Routes />
          </ConnectedRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}

