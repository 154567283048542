import actions from '../../actions';

const msg = {
  'auth/wrong-password': 'Senha inválida ou essa conta não tem senha',
  'auth/popup-closed-by-user': 'Popup fechado antes de concluir o processo de autenticação',
  'auth/user-not-found': 'Usuário não encontrado com o email solicitado. Preferível ir para criar conta na opção abaixo do formulário',
  'auth/not-active': 'Usuário inativo ou aguardando aprovação da primeira conta',
  'auth/too-many-requests': 'Muitas tentativas seguidas com o mesmo email, por favor aguarde alguns minutos para tentar novamente'
}

export default (state, action) => {
  if (actions.LOGIN_USER_FAILED === action.type) {

    if(action.payload.response) {

      if (action.payload.response.data.message) {
        return {
          ...state,
          signInError: action.payload.response.data.message,
        }
      }

      return {
        ...state,
        signInError: msg[action.payload.response.data.errors.code]
      }
    }

    return {
      ...state,
      signInError: msg[action.payload.code]
    }
  }


  return state;
}