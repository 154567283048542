import { fork } from 'redux-saga/effects';

import watchMapList from './home/map';
import watchTeamPage from './teams/page';
import watchCheckin from './teams/checkin';

import watchLogin from './user/login';
import watchLogged from './user/logged';
import watchLogout from './user/logout';
import watchRecovey from './user/recovery';

import watchCEP from './account/cep';
import watchSaveAccount from './account/save';
import watchUploadAccount from './account/upload';

import axios from '../axios';

export default function* rootSaga() {
  yield fork(watchMapList, axios);
  yield fork(watchTeamPage, axios);
  yield fork(watchCheckin, axios);

  yield fork(watchLogin, axios);
  yield fork(watchLogged, axios);
  yield fork(watchLogout, axios);
  yield fork(watchRecovey, axios);

  yield fork(watchCEP, axios);
  yield fork(watchSaveAccount, axios);
  yield fork(watchUploadAccount, axios);
}