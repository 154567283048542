import React from 'react';
import { connect } from 'react-redux';
import Map from './Map';
import actions from '../../redux/actions';

class MapView extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    return <Map markers={this.props.markers} />
  }
}

const mapStateToProps = ({ markers }) => ({ markers });
const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch({ type: actions.TEAMS_FETCH_ENTITY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapView);