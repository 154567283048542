import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import states from './states';

const useStyles = makeStyles(() => ({
  root: {

  },
  cep: {
    width: 200,
  }
}));

const AccountDetails = props => {
  const { className, account_edit: formState, onChange, saveHandler, changeCEP, ...rest } = props;

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange(name, value)
  }

  const handleCepChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    if(value.length <= 8) {
      onChange(name, value);
    }
    if(value.length === 8) {
      console.log('Pesq', value);
      changeCEP(value);
    }
  }

  const handleClick = (event) => {
    event.preventDefault();
    saveHandler();
  }

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Informações dessa conta"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                margin="dense"
                name="displayName"
                onChange={handleChange}
                required
                value={formState.values.displayName}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.cep}
                helperText="Digite o CEP sem acentos, ex: 60526155"
                fullWidth
                label="CEP"
                margin="dense"
                name="cep"
                onChange={handleCepChange}
                required
                value={formState.values.cep}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Endereço"
                margin="dense"
                name="endereco"
                onChange={handleChange}
                required
                value={formState.values.endereco}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Número"
                margin="dense"
                name="numero"
                onChange={handleChange}
                value={formState.values.numero}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                margin="dense"
                name="complemento"
                onChange={handleChange}
                value={formState.values.complemento}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                margin="dense"
                name="bairro"
                onChange={handleChange}
                value={formState.values.bairro}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                margin="dense"
                name="cidade"
                onChange={handleChange}
                value={formState.values.cidade}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                margin="dense"
                name="uf"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={formState.values.uf}
                variant="outlined"
              >
                {states.map(option => (
                  <option key={option.sigla} value={option.sigla}>
                    {option.nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefone"
                margin="dense"
                name="phone"
                onChange={handleChange}
                value={formState.values.phone}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
