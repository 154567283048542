import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  details: {
    display: 'flex',
    position: 'relative',
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  belt: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    // visibility: 'hidden',
  }
}));

const AccountProfile = props => {
  const { className, account, saveHandler, profileCompleteness, ...rest } = props;
  const classes = useStyles();
  const profile = account.profile? {
    ...account.profile,
    photoURL: account.photoURL,
    email: account.email,
  }: {
    displayName: account.displayName,
    photoURL: account.photoURL,
    email: account.email,
  };

  const user = {
    timezone: 'GTM-3',
  };

  const onFileLoad = (e, file) => {
    saveHandler(e.target.result, file.type, file.name)
  }

  const onInputChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader();
    reader.onload = (e) => onFileLoad(e, file);
    reader.readAsDataURL(file);
  }

  const handleUpload = () => {
    const input = document.querySelector('#avatarAccount')
    input.click()
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <img className={classes.belt} src="/images/belts/blue_belt_thumb.png" />
      <CardContent>
        
        <div className={classes.details}>
          
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {profile.displayName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {profile.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment().format('hh:mm A')} ({user.timezone})
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={profile.photoURL}
          />
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">Perfil completo em: {profileCompleteness}%</Typography>
          <LinearProgress
            value={profileCompleteness}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <input type="file" id="avatarAccount" accept="image/*" style={{ display: 'none' }} onChange={onInputChange} />
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
          onClick={handleUpload}
        >
          Upload Imagem
        </Button>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
