import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import actions from '../actions';

export const initialState = {
  initialUrl: null,
  user: { email: null },
  submit_success: '',
  sent_email: {
    isValid: false,
    values: { email: '' },
    touched: {},
    errors: {}
  },
  account_edit: {
    isValid: false,
    values: {
      displayName: '',
      photoURL: '',
      cep: '',
      endereco: '',
      numero: '',
      bairro: '',
      cidade: '',
      uf: '',
      phone: '',
    },
    touched: {},
    errors: {}
  },
  signIn: {
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  },
  team: {locations: [], labels: [], instructors: []},
  markers: [
    // { 
    //   id: 1,
    //   key: 'marker1', 
    //   position: [-23.6178348, -46.5708945], 
    //   name: 'Alliance São Caetano',
    // },
    // { 
    //   id: 2,
    //   key: 'marker2', 
    //   position: [-23.550306, -46.589133],
    //   name: 'Alliance Mooca - Academia Espaço do Corpo',
    //   logo: '',
    //   cover: 'https://www.allianceofficial.com/adm/storage/unidades/fabio-gurgel-1.png',
    //   site: 'www.espacodocorpo.com.br',
    //   contact: 'casquinha.alliance@gmail.com, 55 11 2606-9250',
    //   address: 'Rua Tobias Barreto 847, São Paulo, SP - 03176-000',
    //   instructors: ['Luciano Nucci “Casquinha'],
    // },
    // { 
    //   id: 3,
    //   key: 'marker3', 
    //   position: [-23.544490, -46.554003], 
    //   name: 'Alliance Tatuapé',
    //   site: 'www.alliancetatuape.com.br',
    //   contact: 'alliancetatuape@gmail.com,  55 11 3589-5828',
    //   address: 'Rua Henrique Dumont,350, São Paulo, SP ',
    //   instructors: ['Ricardo Quadros (Sardinha)'],
    // },
    // { 
    //   id: 4,
    //   key: 'marker4', 
    //   position: [-27.2467593, 153.0342534],
    //   name: 'ALLIANCE BRISBANE',
    //   site: 'www.bjjbrisbane.com.au',
    //   contact: 'bruno538@gmail.com, bruno@bjjalliance.com.au',
    //   address: '51 Ariel St Nº, Brisbane, Australia',
    //   instructors: ['Bruno Lemos'],
    // },
  ],
};

const createReducer = (history) => {
  const fn = connectRouter(history);

  return (state = initialState, action) => {

    if (action.type === LOCATION_CHANGE) {
      const { pathname } = action.payload.location;
      return {
        ...state,
        initialUrl: !state.initialUrl ? pathname: state.initialUrl,
        router: fn({
            ...action.payload,
            user: state.user,
        }),
      }
    }

    if (action.meta && action.meta.reducer) {
      return action.meta.reducer(state, action);
    }

    if (action.type === actions.RELOAD) {
      return {
         ...state,
         user: {},
      }
    }

    return state;

  }
}

export default createReducer;