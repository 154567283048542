import actions from '../actions';

const msg = {
  'auth/email-already-exists': 'Email já existe',
  'auth/email-already-in-use': 'Email já está em uso, digite a senha corretamente para criar nova organização com esse mesmo email',
  'auth/weak-password': 'Senha fraca, deve ter pelo menos 6 caracteres',
  'auth/invalid-password': 'Senha fraca, deve ter pelo menos 6 caracteres',
  'auth/wrong-password': 'Esse email já está cadastrado e a senha está inválida',
  'auth/user-not-found': 'Email não encontrado',
  'auth/too-many-requests': 'Bloqueamos todos os pedidos deste dispositivo devido a atividades incomuns. Tente mais tarde.',
  'auth/invalid-action-code': 'O código de ação é inválido. Isso pode acontecer se o código estiver malformado, expirado ou já tiver sido usado.',
}

export default (state, action) => {
  if (actions.SIGN_UP_FAILED === action.type) {

    if(action.payload.code) {
      return {
        ...state,
        signUpError: msg[action.payload.code]
      }
    }
    if(action.payload.response) {
      return {
        ...state,
        signUpError: msg[action.payload.response.data.errors.code]
      }
    }

  }
  return state;
}