import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Teams from '../../components/Teams'
import styles from './styles';

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
        <Teams id={props.match.params.id} />
      </div>
    </div>
  );
}