import { put, takeLatest, select } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/signUpValidation';
import crudReducer from '../../reducer/signIn';

function* recoveryUser(axios, action) {
  try {
    const sent_email = yield select(state => state.sent_email);
    const { data: { data } } =  yield axios.post('/auth/recovery', sent_email.values);
    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL,
      meta: { 
        reducer: crudReducer, 
      },
      payload: {
        submit_success: data,
        signUpError: null,
      },
    });
  } catch (err) {
    yield put({
      type: actions.SIGN_UP_FAILED,
      payload: err,
      meta: {
        reducer,
      },
      ga: { category: 'ERROR', action: action.type } 
    });
  }
}

export default function* watchRecovery(axios) {
  yield takeLatest(actions.RECOVERY_USER, recoveryUser, axios);
}