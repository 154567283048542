import React from 'react';
import {
  Button,
} from "@material-ui/core";
import { AccountGroup } from 'mdi-material-ui';

export default ({
  classes,
  user,
}) => {

  if (!user.accounts) {
    return null;
  }

  // if (user.accounts && user.accounts.length === 1) {
  //   return (
  //     <Button
  //       variant="contained"
  //       className={classes.button}
  //       startIcon={<AccountGroup />}
  //     >
  //       Meu Time
  //     </Button>
  //   )
  // }

  return (
    <Button
      variant="contained"
      className={classes.button}
      startIcon={<AccountGroup />}
    >
      Meu Time
    </Button>
  )

}