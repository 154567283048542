import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './style';
import {
  Grid,
  TextField,
  Typography,
  Link,
  Button,
} from '@material-ui/core';

class AccountRecovery extends React.Component {
  render() {
    const { sent_email: formState, onChange, classes, onSubmit, signUpError, submit_success } = this.props;
    const hasError = field =>
      formState.touched[field] && 
      formState.errors[field] ? true : false;
    
    const handleChange = event => {
      event.persist();
      onChange(event.target.name,  event.target.value)
    }

    const submitHandler = (event) => {
      event.preventDefault();
      onSubmit();
    }

    const msgError = (signUpError) ? <Typography gutterBottom color="error">{signUpError}</Typography>: null;
    const msgSuccess = (submit_success) ? <Typography gutterBottom color="primary">{submit_success}</Typography>: null;

    return (      
      <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                name="loginForm"
                className={classes.form}
                onSubmit={submitHandler}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Preciso recuperar minha senha
                </Typography>

                {msgSuccess}
                {msgError}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Enviar email
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Já tem uma conta?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-in"
                    variant="h6"
                  >
                    Entrar
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
    )
  }
}

export default styles(AccountRecovery);
