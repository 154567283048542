import React, { Fragment } from 'react';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { Link as RouterLink } from 'react-router-dom';

const position = [-23.6179302,-46.5725223];

const MyPopupMarker = ({ name, position, id }) => (
  <Marker
    position={position}
    icon={L.icon({
      iconUrl: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      iconSize: [40, 40],
      // className: 'marker_pacote',
    })}
  >
    <Popup
      opacity={1}
      direction="top"
      offset={L.point(5, -10)}
    >
      <RouterLink to={`/teams/${id}`}>
        {name}
      </RouterLink>

    </Popup>
  </Marker>
)

const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ id, ...props }) => (
    <MyPopupMarker key={`marker-${id}`} id={id} {...props} />
  ))
  return <Fragment>{items}</Fragment>
}

export default (props) => {
  const { markers } = props;
  const bounds = [position, [-23.544490, -46.554003], ...markers];
  return (
    <Map
      center={position}
      zoom={14}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />
      <MyMarkersList markers={markers} />
    </Map>
  )
}
