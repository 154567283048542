import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from "@material-ui/core";
import { Login, AccountGroup } from 'mdi-material-ui';
import { Link as RouterLink } from 'react-router-dom';
import Logged from './Logged';
import MyTeams from './MyTeams';

import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();
  const onGoMyTeam = event => {
    event.preventDefault();
    dispatch(push(`/teams/1`))
  }

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.absolute]: 'absolute',
    [classes.fixed]: 'fixed'
  });

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <RouterLink to="/home">
          <img alt="Logo" src="/images/android-chrome-36x36.png" />
        </RouterLink>
        <div className={classes.flex}>
          <MyTeams user={user} classes={classes} />
        </div>
        <Logged user={user} dispatch={dispatch} classes={classes} />
      </Toolbar>

    </AppBar>
  );
}
