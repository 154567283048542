import { takeLatest, put, select } from 'redux-saga/effects';
import actions from '../../actions';

function* prepareSaveEntity(axios, action) {
  try {
    const  { user, account_edit } = yield select(state => state);
    yield axios.request({
      method: 'put',
      url: `/api/accounts/${user.id}`,
      data: {
        profile: account_edit.values
      }
    });
    yield put({ type: actions.LOGGED_USER });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type } 
    });
  }

}

export default function* watchSaveEntity(axios) {
  yield takeLatest(actions.SAVE_ACCOUNT, prepareSaveEntity, axios);
}