import React from 'react';
import { connect } from 'react-redux';
import Team from './Team';
import actions from '../../redux/actions';

class Teams extends React.Component {

  componentDidMount() {
    this.props.fetch(this.props.id);
  }

  render() {

    return (
      <Team team={this.props.team} />
    )
  }
};

const mapStateToProps = ({ team }) => ({ team });
const mapDispatchToProps = dispatch => ({
  fetch: (payload) => dispatch({ 
    type: actions.TEAM_FETCH_ENTITY,
    payload 
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
