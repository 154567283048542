import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { AccountProfile, AccountDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 55,
    padding: theme.spacing(4)
  }
}));

const Account = ({ 
  account, 
  account_edit, 
  profileCompleteness,
  saveHandler,
  saveProfileHandler, 
  onChange, 
  changeCEP
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile
            account={account}
            saveHandler={saveHandler}
            profileCompleteness={profileCompleteness}
          />
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails
            saveHandler={saveProfileHandler}
            onChange={onChange}
            changeCEP={changeCEP}
            account_edit={account_edit}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
