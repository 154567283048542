import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './style';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';

const SignIn = ({ formState, loginWithPassword, handleFormChange, signInError }) => {

  const classes = useStyles();

  const handleChange = event => {
    event.persist();
    handleFormChange({
      [event.target.name]: event.target.value,
    });
  }

  const handleSignInWithPassword = event => {
    event.preventDefault();
    if (loginWithPassword) {
      loginWithPassword();
    }
  }

  const msgError = (signInError) ? 
    <Typography
      gutterBottom
      color="error"
    >{signInError}</Typography>: null;

  const hasError = field => {
    return !!formState.errors[field];
  }
    

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                name="loginForm"
                className={classes.form}
                onSubmit={handleSignInWithPassword}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Já tenho conta
                </Typography>
                {msgError}
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Senha"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Entrar agora
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="right"
                >
                  Esqueci minha {' '}
                  <Link
                    component={RouterLink}
                    to="/recovery"
                    variant="h6"
                  >
                    senha
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// SignIn.propTypes = {
//   history: PropTypes.object
// };

export default (SignIn);
