import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import reducer from '../../redux/reducer/signIn';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import SignIn from '../../components/SignIn';

const useStyles = makeStyles(theme => ({
  main: {
    background: "#FFFFFF",
    position: "absolute",
    marginTop: 120,
  },
  mainRaised: {
    minWidth: '100%',
  },
  container: {
    color: "#FFFFFF",
    minWidth: '100%',
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
}));

export default withRouter(() => {
  const classes = useStyles();
  const { signInError, signIn } = useSelector(state => state);
  const dispatch = useDispatch();
  const loginWithPassword = (payload) => dispatch({ type: actions.LOGIN_USER_PASSWORD, payload });
  const handleFormChange = payload => dispatch({
    type: actions.SIGN_IN_CHANGE, 
    payload,
     meta: { reducer },
  });
  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
        <SignIn
          formState={signIn}
          signInError={signInError}
          loginWithPassword={loginWithPassword}
          handleFormChange={handleFormChange}
        />
      </div>
    </div>
  );

});