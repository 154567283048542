import React from 'react';
import Account from '../../components/Account';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import reducer from '../../redux/reducer/crud';

class AccountPage extends React.Component {
  render() {
    return (
      <Account {...this.props} />
    )
  }
}

export default connect(
  ({ user: account, account_edit, profileCompleteness }) => 
    ({ account, account_edit, profileCompleteness }),
  dispatch => ({
    saveHandler: (file, contentType, name) => {
      dispatch({
        type: actions.UPLOAD_AVATAR,
        payload: { file, contentType, name },
      });
    },
    saveProfileHandler: () => {
      dispatch({ type: actions.SAVE_ACCOUNT });
    },
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'account_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema: {},
        },
      });
    },
     changeCEP: (payload) => (
      dispatch({
        type: actions.FETCH_CEP,
        payload,
      })
    ),
  })
)(AccountPage)