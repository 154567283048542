import { takeLatest, put } from 'redux-saga/effects';
import actions from '../../actions';
import genericErrors from '../../actions/genericErrors';

function* prepareLogout(axios, action) {
  try {
    yield axios.post('/logout');
    yield put({ type: actions.RELOAD });
  } catch (err) {
    yield put(genericErrors(action.type, err));
  }
}

export default function* watchLogout(axios) {
  yield takeLatest(actions.LOGOUT_USER, prepareLogout, axios);
}