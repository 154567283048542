import { put, takeLatest, select } from 'redux-saga/effects';
import actions from '../../actions';
import reducer from '../../reducer/signIn/signInValidation';
import { push } from 'connected-react-router';

function* loginUser(axios, action) {
  try {
    const { signIn: { values }, initialUrl } = yield select(state => state)
    yield axios.post('/login', values);
    yield put({ type: actions.LOGGED_USER });
    yield put(push(initialUrl));
  } catch (err) {
    console.log(err);
    yield put({
      type: actions.LOGIN_USER_FAILED,
      payload: err,
      meta: {
        entity: 'user',
        reducer,
      },
      ga: { category: 'ERROR', action: action.type } 
    });
  }
}

export default function* watchLogin(axios) {
  yield takeLatest(actions.LOGIN_USER_PASSWORD, loginUser, axios);
}