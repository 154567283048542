import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Header from '../header';

const RouteWithLayout = props => {
  const { 
    component: Component, 
    location: { pathname },
    ...rest
  } = props;
  const { initialUrl, loading, user } = useSelector(state => state);

  const redirect = { url: null };

  if (!user.id && pathname === '/account') {
    redirect.url = '/sign-in';
  }

  if (user.id && pathname === '/sign-in') {
    redirect.url = (initialUrl === '/sign-in')? '/': initialUrl;
  }

  return (
    <Route
      {...rest}
      render={
        matchProps => {
          return redirect.url? 
            <Redirect
              to={{
                pathname: redirect.url,
                state: { from: matchProps.location }
              }}
            /> : 
            <div className="App">
              <Header />
              <Component {...matchProps} />
            </div>
        }
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
