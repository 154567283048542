import React from 'react';
import classNames from "classnames";
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import reducer from '../../redux/reducer/crud';
import AccountRecovery from '../../components/AccountRecovery';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    background: "#FFFFFF",
    position: "absolute",
    marginTop: 120,
  },
  mainRaised: {
    minWidth: '100%',
  },
  container: {
    color: "#FFFFFF",
    minWidth: '100%',
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};

const RecoveyView = props => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
        <AccountRecovery
          {...props}
        />
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  const { sent_email, signUpError, submit_success } = state; 
  return {
    sent_email, signUpError, submit_success
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'sent_email',
          touched: {
            [name]: true
          },
          reducer,
          schema,
        },
      });
    },
    onSubmit: (value) => {
      dispatch({
        type: actions.RECOVERY_USER,
      });
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RecoveyView);