
import actions from '../actions';
import validate from 'validate.js';

export default (state, action) => {

  if (actions.POPULATE_ENTITY === action.type) {
    const oldState = state[action.meta.entity];

    const newState = {
      ...oldState,
      // errors: errors ? errors : {},
      // isValid: !errors,
      values: {
        ...oldState.values,
        ...action.payload,
      },
      touched: {
        ...oldState.touched,
        ...action.meta.touched,
      }
    }

    const errors = validate(newState.values, action.meta.schema);

    console.log('populate', newState.values, !errors);

    return {
      ...state,
      [action.meta.entity]: {
        ...newState,
        errors: errors ? errors : {},
        isValid: !errors,
      }
    }
  }

  if (actions.PAGINATE_ENTITY === action.type) {
    const stateEntity = state[action.meta.entity];
    return {
      ...state,
      [action.meta.entity]: {
        ...stateEntity,
        ...action.payload,
      },
    }
  }

  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {

    if (action.meta && action.meta.pagination) {
      return {
        ...state,
        [action.meta.entity]: {
          per_page: 10,
          ...state[action.meta.entity],
          ...action.payload,
        },
      };
    }

    return {
      ...state,
      [action.meta.entity]: action.payload,
    };
  }
  return state;
}