import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';
import WorkWeek from '../Calendar/WorkWeek';
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'moment/locale/pt-br';

import selectors from './selectors';

const localizer = momentLocalizer(moment);

const Event = ({ event }) => {
// console.log(event)
  return (
    <div className="custom-event" style={{ backgroundColor: event.color }}>
        <div><strong>{event.title}</strong></div>
    </div>
  )
}


const genHeaderCell = () => ({ date }) => {  
  return (
    <div>
      {moment(date).format('ddd')}
    </div>
  );
};

const generateEvents = (events) => {

  const week = moment().startOf('isoWeek');

  return events.map(event => {
    const startDay = week.clone().day(event.dayOfWeek).set({ 'hour': event.startHour, 'minute': event.startMinute });
    const endDay = week.clone().day(event.dayOfWeek).set({ 'hour': event.endHour, 'minute': event.endMinute });
    return {
      title: event.name,
      start: startDay.toDate(),
      end: endDay.toDate(),
      color: event.color,
      resourceId: event.id,
    }
  })

}

export default ({
  locations
}) => {

  const dispatch = useDispatch();

  const handleOnSelectEvent = (event, e) => {
    console.log('event selected', event)
    dispatch(selectors.checkin(event));
  };

  return (
    <CardContent>
      {
        locations.map(location => (
          <Calendar
            onSelectEvent={handleOnSelectEvent}
            defaultView={Views.WEEK}
            toolbar={false}
            views={{ week: WorkWeek }}
            culture="pt-BR"
            localizer={localizer}
            events={generateEvents(location.events)}
            min={moment().startOf('day').add(16, 'hour').toDate()}
            max={moment().endOf('day').subtract(1, 'hour').toDate()}
            components={{
              event: Event,
              week: {
                header: genHeaderCell()
              },
            }}
          />
        ))
      }
    </CardContent>
  )
}