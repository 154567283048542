import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Chip from '@material-ui/core/Chip';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';

import { Link, Youtube } from 'mdi-material-ui';

import styles from './styles';
import Calendars from './Calendars';

const useStyles = makeStyles(styles);

export default function TeamViewCard({ team }) {
  const classes = useStyles();
  const { locations, labels } = team;

  const handleShare = event => {
    event.preventDefault();
    navigator.share({
      title: team.name,
      text: `Fulano de tal compartilhou ${team.name} com voce`,
      url: `https://jiujitsuteam.com/teams/${team.id}`,
    })
    .then(() => console.log("Shared!"))
    .catch(err => console.error(err));
  }

  const link = team.site && team.site.length?
    <IconButton aria-label="site" href={team.site}>
      <Link />
    </IconButton> : null;
  const share = (navigator.share) ?
    <IconButton aria-label="share" onClick={handleShare}>
      <ShareIcon />
    </IconButton> : null;

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar} src={team.logo}>
            JJ
          </Avatar>
        }
        title={team.name}
        subheader={team.instructors.join(', ')}
      />
      <CardMedia
        className={classes.media}
        image={team.cover}
        title={team.name}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {team.address}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {link}
        {share}
        <IconButton aria-label="videos">
          <Youtube />
        </IconButton>
        <div className={classes.labels}>
          { labels.map(item => <Chip label={item.name} style={{ backgroundColor: item.color, color: '#fff' }} />)}
        </div>
      </CardActions>
      <Calendars locations={locations} />
    </Card>
  );
}